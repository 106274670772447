<template>
    <modal ref="modalBuscarConvenio" titulo="Convenio de nómina" tamano="modal-lg" no-cancelar no-adicional>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 my-3 j-center">
                <div class="col-10">
                    <p class="f-600 f-17 mb-3">
                        Tengo un código de convenio de nómina
                    </p>
                    <div class="d-flex">
                        <div class="col-10 pl-0">
                            <ValidationProvider rules="required" name="Nombres">
                                <el-input v-model="query" placeholder="Escribe el código" class="w-100" @keyup.enter.native="goSearch" />
                            </ValidationProvider>
                            <span v-if="isNull" class="text-danger w-100 f-12 ml-3">El código ingresado no pertenece a ningún convenio</span>
                            <span v-if="isInactive" class="text-danger w-100 f-12 ml-3">El código ingresado se encuentra asociado a un convenio inactivo</span>
                        </div>
                        <div class="btn-general px-4" @click="goSearch">
                            Buscar
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/nomina/nomina'

export default {
    data(){
        return {
            isInactive: false,
            isNull: false,
            query: ''
        }
    },
    methods: {
        async goSearch(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;

                this.isNull = false;
                this.isInactive = false
                //
                const {data} = await Service.getSearchConvenio(this.query)
                if(!data.convenio){
                    return this.isNull = true;
                }
                //
                if(data.convenio.estado == 0){
                    return this.isInactive = true;
                }
                //
                this.next(data.convenio.id);
            } catch(e){
                this.errorCatch(e)
            }
        },

        async next(id){
            this.$refs.modalBuscarConvenio.toggle();
            this.$router.push({name: 'convenio', params:{idConvenio: id}});
        },

        toggle(){
            this.limpiar();
            this.$refs.modalBuscarConvenio.toggle();
        },

        limpiar(){
            this.isInactive = false;
            this.isNull = false;
            this.query = '';
        },

        aceptar(){
            this.$refs.modalBuscarConvenio.toggle();
            this.$router.push({name: 'convenio'})
        }
    }
}
</script>
